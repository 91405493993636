<template>
  <div class="forbidden-state">
    <div class="empty">
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'lock']" size="3x"></fa-icon>
      </div>
      <p class="empty-title h5">O sistema está suspenso!</p>
      <p class="empty-subtitle">
        O sistema está temporariamente indisponível por questões administrativas.
        <br>
        <span class="text-info">Entre em contato com o administrador para mais informações.</span>
      </p>
      <div class="empty-action" v-if="hasAction">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpiredState',
  computed: {
    hasAction() {
      return 'default' in this.$slots;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.forbidden-state {
  margin: 0 auto;
  max-width: $control-width-lg;
  .empty {
    background-color: $light-color;
    border: $border-width solid $border-color;
  }
  .empty-icon {
    svg {
      color: $warning-color;
    }
  }
}
</style>
