export const AUTHORIZE_DASHBOARD = 'clinical:authorizeDashboard';
export const LIST_APPOINTMENTS = 'clinical:listAppointments';
export const CREATE_APPOINTMENT = 'clinical:createAppointment';
export const UPDATE_APPOINTMENT = 'clinical:updateAppointment';
export const DELETE_APPOINTMENT = 'clinical:deleteAppointment';
export const BLOCK_APPOINTMENT = 'clinical:blockAppointment';
export const PRINT_APPOINTMENT_LIST = 'clinical:printAppointmentList';
export const PRINT_APPOINTMENT_PRODUCTION_LIST = 'clinical:printAppointmentProductionList';

export const LIST_ATTENDANCES = 'clinical:listAttendances';
export const PRINT_MEDICAL_RECORD_SCHEDULED = 'clinical:printMedicalRecordScheduled';
export const PRINT_APPOINTMENTS_BY_ROOM = 'clinical:printAppointmentsByRoom';

export const LIST_MEDICAL_RECORDS = 'clinical:listMedicalRecords';
export const CREATE_MEDICAL_RECORD = 'clinical:createMedicalRecord';

export const LIST_MEDICAL_REPORTS = 'clinical:listMedicalReports';
export const CREATE_MEDICAL_REPORT = 'clinical:createMedicalReport';
export const REASSIGN_MEDICAL_REPORT = 'clinical:reassignMedicalReport';

export const LIST_COMMITMENTS = 'clinical:listCommitments';
export const CREATE_COMMITMENT = 'clinical:createCommitment';
export const UPDATE_COMMITMENT = 'clinical:updateCommitment';
export const DELETE_COMMITMENT = 'clinical:deleteCommitment';

export const LIST_SCHEDULES = 'clinical:listSchedules';
export const CREATE_SCHEDULE = 'clinical:createSchedule';
export const UPDATE_SCHEDULE = 'clinical:updateSchedule';
export const DELETE_SCHEDULE = 'clinical:deleteSchedule';
export const PRINT_SCHEDULE_LIST = 'clinical:printScheduleList';

export const LIST_TEMPLATES = 'clinical:listTemplates';
export const CREATE_TEMPLATE = 'clinical:createTemplate';
export const UPDATE_TEMPLATE = 'clinical:updateTemplate';
export const DELETE_TEMPLATE = 'clinical:deleteTemplate';
export const PRINT_TEMPLATE_LIST = 'clinical:printTemplateList';

export const MANAGE_APPOINTMENT_SETTINGS = 'clinical:manageAppointmentSettings';

export const LIST_SERVICES = 'clinical:listServices';
export const CREATE_SERVICE = 'clinical:createService';
export const UPDATE_SERVICE = 'clinical:updateService';
export const DELETE_SERVICE = 'clinical:deleteService';

export const AUTHORIZE_SPECIAL_RESOURCES = 'clinical:authorizeSpecialResources';
export const REPORT_EXAM_PRODUCTION = 'clinical:reportExamProduction';

export default [
  {
    action: AUTHORIZE_DASHBOARD,
    name: 'Dashboard administrativo: Autorizar',
  },
  {
    action: LIST_APPOINTMENTS,
    name: 'Agendamentos: Listar',
  },
  {
    action: CREATE_APPOINTMENT,
    name: 'Agendamentos: Criar',
  },
  {
    action: UPDATE_APPOINTMENT,
    name: 'Agendamentos: Atualizar',
  },
  {
    action: DELETE_APPOINTMENT,
    name: 'Agendamentos: Excluir',
  },
  {
    action: BLOCK_APPOINTMENT,
    name: 'Agendamentos: Bloquear',
  },
  {
    action: PRINT_APPOINTMENT_LIST,
    name: 'Agendamentos: Imprimir lista',
  },
  {
    action: PRINT_APPOINTMENT_PRODUCTION_LIST,
    name: 'Agendamentos: Imprimir produção dos colaboradores',
  },
  {
    action: LIST_ATTENDANCES,
    name: 'Atendimentos: Listar',
  },
  {
    action: LIST_MEDICAL_RECORDS,
    name: 'Prontuário: Listar',
  },
  {
    action: CREATE_MEDICAL_RECORD,
    name: 'Prontuário: Criar',
  },
  {
    action: PRINT_MEDICAL_RECORD_SCHEDULED,
    name: 'Prontuário: Imprimir finalizados com data de retorno',
  },
  {
    action: LIST_MEDICAL_REPORTS,
    name: 'Central de laudos: Listar',
  },
  {
    action: CREATE_MEDICAL_REPORT,
    name: 'Central de laudos: Criar',
  },
  {
    action: REASSIGN_MEDICAL_REPORT,
    name: 'Central de laudos: Reassinar',
  },
  {
    action: LIST_COMMITMENTS,
    name: 'Compromissos: Listar',
  },
  {
    action: CREATE_COMMITMENT,
    name: 'Compromissos: Criar',
  },
  {
    action: UPDATE_COMMITMENT,
    name: 'Compromissos: Atualizar',
  },
  {
    action: DELETE_COMMITMENT,
    name: 'Compromissos: Excluir',
  },
  {
    action: LIST_SCHEDULES,
    name: 'Gerenciamento de agendas: Listar',
  },
  {
    action: CREATE_SCHEDULE,
    name: 'Gerenciamento de agendas: Criar',
  },
  {
    action: UPDATE_SCHEDULE,
    name: 'Gerenciamento de agendas: Atualizar',
  },
  {
    action: DELETE_SCHEDULE,
    name: 'Gerenciamento de agendas: Excluir',
  },
  {
    action: PRINT_SCHEDULE_LIST,
    name: 'Gerenciamento de agendas: Imprimir lista',
  },
  {
    action: LIST_TEMPLATES,
    name: 'Modelos de impressos: Listar',
  },
  {
    action: CREATE_TEMPLATE,
    name: 'Modelos de impressos: Criar',
  },
  {
    action: UPDATE_TEMPLATE,
    name: 'Modelos de impressos: Atualizar',
  },
  {
    action: DELETE_TEMPLATE,
    name: 'Modelos de impressos: Excluir',
  },
  {
    action: PRINT_TEMPLATE_LIST,
    name: 'Modelos de impressos: Imprimir lista',
  },
  {
    action: MANAGE_APPOINTMENT_SETTINGS,
    name: 'Configurações: Parâmetros de agendamento',
  },
  {
    action: LIST_SERVICES,
    name: 'Serviços: Listar',
  },
  {
    action: CREATE_SERVICE,
    name: 'Serviços: Criar',
  },
  {
    action: UPDATE_SERVICE,
    name: 'Serviços: Atualizar',
  },
  {
    action: DELETE_SERVICE,
    name: 'Serviços: Excluir',
  },
  {
    action: AUTHORIZE_SPECIAL_RESOURCES,
    name: 'Recursos especiais: Autorizar',
  },
  {
    action: REPORT_EXAM_PRODUCTION,
    name: 'Relatórios: Produção dos profissionais (Exames)',
  },
  {
    action: PRINT_APPOINTMENTS_BY_ROOM,
    name: 'Relatórios: Agendamentos por sala',
  },
];
