import localforage from 'localforage';
import { setToken } from 'src/plugins/http';

export default (store) => {
  store.subscribe((mutation, { auth }) => {
    if (mutation.type === 'Auth.SET_TOKEN') {
      setToken(auth.token);
      localforage.setItem('token', auth.token);
    } else if (mutation.type === 'Auth.SET_MODULE') {
      localforage.setItem('module', auth.module);
    }
  });
};
