<template>
  <dx-modal title="Conta padrão"
            id="modal-forward-account" size="lg"
            :value="show" @input="close(false)">
    <div class="loading loading-lg" v-if="loading" />
    <div class="forward-account" v-else>
      <template v-if="finished">
        <div class="text-warning text-bold">
          * Essa conta já foi finalizada. Por esse motivo não será possível editá-la!
        </div>
        <div class="columns">
          <div class="column col-4 col-sm-12 form-group">
            <label class="form-label">Paciente</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.patient.name" readonly>
          </div>
          <div class="column col-4 col-sm-12 form-group">
            <label class="form-label">Convênio</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.patient.insurance.name" readonly>
          </div>
          <div class="column col-4 form-group">
            <label class="form-label">Fatura</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.invoice.code" readonly>
          </div>
        </div>
        <div class="divider" />
        <div class="columns">
          <div class="column col-4 col-sm-12 form-group">
            <label class="form-label">Número da guia</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.referral.insurance" readonly>
          </div>
          <div class="column col-8">
            <label class="form-label">Profissional executante</label>
            <input type="text" class="form-input text-bold"
                   :value="form.professionalName" readonly>
          </div>
        </div>
        <h6 class="h6">Procedimentos</h6>
        <table class="table table-striped">
          <thead>
          <tr>
            <th>#</th>
            <th>Data</th>
            <th>Código</th>
            <th>Nome</th>
            <th>Profissional</th>
            <th class="text-center">Quantidade</th>
            <th class="text-right">Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(expense, i) in form.expenses" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ expense.startDate | date }}</td>
            <td>{{ expense.code | tuss }}</td>
            <td>{{ expense.name }}</td>
            <td>{{ expense.professional ? expense.professional.name : '-' }}</td>
            <td class="text-center">{{ expense.quantity }}</td>
            <td class="text-right">{{ expense.value.total | currency }}</td>
          </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <div class="columns">
          <div class="column col-4 col-sm-12 form-group">
            <label class="form-label">Paciente</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.patient.name" readonly>
          </div>
          <div class="column col-4 col-sm-12 form-group">
            <label class="form-label">Convênio</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.patient.insurance.name" readonly>
          </div>
          <div class="column col-4 form-group" v-if="invoiceCodes.length > 0">
            <label class="form-label">Fatura</label>
            <select id="invoice" v-if="hasInvoice"
                    class="form-select" v-model="form.invoiceId">
              <option v-if="!isCurrentInvoice" value="">Fatura vigente</option>
              <option v-for="(item, i) in invoiceCodes"
                      :value="item.id" :key="i">
                {{ item.code }} - {{ item.date | date }}
                <span v-if="item.status === 'open'">(Vigente)</span>
              </option>
            </select>
            <input type="text" class="form-input"
                   v-model="form.invoice.code"
                   readonly v-else />
          </div>
        </div>
        <div class="divider" />
        <div class="columns">
          <div class="column col-4 col-sm-12 form-group"
               :class="{'has-error': $v.form.referral.insurance.$error}">
            <label class="form-label">Número da guia</label>
            <input type="text" class="form-input" v-model="form.referral.insurance"
                   @blur="$v.form.referral.insurance.$touch()"
                   placeholder="W2SB43KE">
            <template v-if="$v.form.referral.insurance.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.referral.insurance.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-8" :class="{'has-error': $v.form.professionalId.$error}">
            <label class="form-label">Profissional executante</label>
            <select id="professional" class="form-select"
                    @blur="$v.form.professionalId.$touch()"
                    v-model="form.professionalId">
              <option v-for="(item, i) in professionals"
                      :value="item.id" :key="i">
                {{ item.name }} - {{ item.specialty.name }}
              </option>
            </select>
            <template v-if="$v.form.professionalId.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.professionalId.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
        <expense-items
          :source="source"
          :expenses="form.expenses"
          :general-rules="planSettings.general"
          :insurance-plan-id="form.patient.insurance.plan.id"
        />
      </template>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1" :class="{loading: saving}"
              v-if="!finished"
              @click="save" :disabled="saving">
        Finalizar
      </button>
      <button class="btn"
              @click="close(false)">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';
import { mapActions, mapMutations, mapState } from 'vuex';
import { required } from 'vuelidate/src/validators';
import ExpenseItems from '../../../billing/attendance/components/ExpenseItems.vue';

export default {
  mixins: [formMixin],
  components: {
    ExpenseItems,
  },
  props: {
    source: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    data: {
      id: {
        type: String,
      },
      key: {
        type: String,
      },
      startDate: {
        type: String,
      },
      appointmentId: {
        type: String,
      },
      invoiceId: {
        type: String,
      },
      professionalId: {
        type: String,
      },
      referral: {
        type: {
          type: String,
        },
        insurance: {
          type: String,
        },
      },
      patient: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        birthDate: {
          type: Date,
        },
        gender: {
          type: Date,
        },
        insurance: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          plan: {
            id: {
              type: String,
            },
            name: {
              type: String,
            },
          },
          record: {
            type: String,
          },
          validity: {
            type: Date,
          },
          insured: {
            record: {
              type: String,
            },
            name: {
              type: String,
            },
            validity: {
              type: Date,
            },
          },
        },
      },
      expenses: {
        type: Array,
      },
    },
  },
  data() {
    return {
      path: '/billing-accounts',
      loading: false,
      saving: false,
      isCurrentInvoice: false,
      professionals: [],
      invoiceCodes: [],
      form: this.blankForm(),
      planSettings: this.blankPlanSettingsForm(),
    };
  },
  validations() {
    const rules = {
      form: {
        professionalId: { required },
        referral: {
          insurance: {},
        },
      },
    };
    if (this.form.consultationType !== 'return') {
      rules.form.referral.insurance = { required };
    }
    return rules;
  },
  created() {
    this.form = mergeFrom(this.blankForm(), this.data);

    if (this.source === 'appointments' && !this.form.id) {
      const [expense] = this.form.expenses;
      if (['consultation', 'exam'].includes(expense.type)) {
        this.form.serviceType = expense.type;
        if (expense.type === 'consultation') {
          this.form.consultationType = expense.return ? 'return' : 'first';
        }
      } else if (['session', 'evaluation'].includes(expense.type)) {
        this.form.serviceType = 'other_therapies';
      } else if (expense.type === 'laboratory') {
        this.form.serviceType = 'exam';
      } else if (expense.type === 'surgery') {
        this.form.serviceType = 'minor_surgery';
      } else {
        this.form.serviceType = 'small_service';
      }
    }
  },
  async mounted() {
    this.loading = true;
    this.loadInvoiceCodes();
    this.loadPlanSettings();
    await this.loadProfessionals();
    if (this.form.professionalId) {
      const foundProfessional = this.professionals
        .find(item => item.id === this.form.professionalId);
      if (foundProfessional) {
        this.form.professionalName = foundProfessional.name;
      }
    }
    this.loading = false;
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      appointment: state => state.appointment.form,
    }),
    finished() {
      return this.form.status === 'finished';
    },
    hasInvoice() {
      return this.invoiceCodes.some(({ id }) => id === this.form.invoiceId);
    },
  },
  methods: {
    ...mapMutations({
      setAccount: 'Appointment.SET_ACCOUNT',
    }),
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    validateItems() {
      return this.$refs.expenseItems.every(item => item.validate());
    },
    close(save = false) {
      this.$emit('close', save ? this.form : null);
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.saving) {
        return null;
      }

      if (this.form.expenses.length === 0) {
        this.$toast.error('Nenhum procedimento adicionado!');
        return null;
      }

      this.saving = true;

      const data = {
        id: this.form.id,
        status: 'open',
        appointmentId: this.form.appointmentId,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        invoiceId: this.form.invoiceId,
        serviceType: this.form.serviceType,
        character: 'elective',
        referral: {
          type: this.type,
          insurance: this.form.referral.insurance,
          provider: this.form.referral.provider,
        },
        insurance: {
          id: this.form.patient.insurance.id,
          planId: this.form.patient.insurance.plan.id,
          insured: {
            record: this.form.patient.insurance.record,
            name: this.form.patient.name,
            validity: this.form.patient.insurance.validity,
          },
        },
        patient: {
          id: this.form.patient.id,
          record: this.form.patient.insurance.record,
          validity: this.form.patient.insurance.validity,
          insured: this.form.patient.name,
        },
      };

      if (this.form.consultationType) {
        data.consultationType = this.form.consultationType;
      }

      data.expenses = this.form.expenses.map(expense => ({
        id: expense.id,
        financialBundleId: expense.financialBundleId,
        startDate: expense.startDate,
        endDate: expense.endDate,
        code: expense.code,
        name: expense.name,
        accountId: expense.accountId,
        quantity: expense.quantity,
        professionalId: expense.professional.id,
        specialtyCode: expense.professional.specialty.code,
        degreeParticipationCode: expense.professional.degreeParticipation,
        way: expense.way ? expense.way : null,
        technique: expense.technique ? expense.technique : null,
        factor: expense.factor,
        value: {
          discount: expense.value.discount || 0,
          interest: expense.value.interest || 0,
          rate: expense.value.rate || 0,
          insurance: expense.value.insurance || 0,
          particular: expense.value.particular || 0,
        },
      }));

      const isNew = !data.id;

      const request = isNew
        ? this.$httpX.post(`${this.path}`, data)
        : this.$httpX.put(`${this.path}/${data.id}`, data);

      return request
        .then(({ data: result }) => {
          this.form.id = result.id;

          result.expenses.forEach((item) => {
            const found = this.$store.state.appointment.form.expenses
              .find(({ id }) => id === item.expense.id);
            if (found) {
              found.return = item.return || false;
              found.value.discount = item.value.discount;
              found.value.feeValue = item.value.fee;
              found.value.filmValue = item.value.film;
              found.value.insurance = item.value.insurance;
              found.value.interest = item.value.interest;
              found.value.operationalCostValue = item.value.operationalCost;
              found.value.particular = item.value.particular;
              found.value.rate = item.value.rate;
              found.value.tax = item.value.tax;
              if (!found.accountId) {
                found.accountId = result.id;
                found.selected = false;
              }
            }
          });

          this.setAccount({
            key: this.data.key,
            expenses: this.$store.state.appointment.form.expenses,
          });

          this.$toast.show('Salvo com sucesso');
          this.close(true);
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
    loadInvoiceCodes() {
      const params = {
        insurancePlanId: this.data.patient.insurance.plan.id,
      };

      return this.$http.get('/billing-invoice-codes', { params })
        .then(({ data }) => {
          this.invoiceCodes = data.items;
          const found = this.invoiceCodes.find(({ status }) => status === 'open');
          this.isCurrentInvoice = !!found;

          if (!this.form.invoiceId && this.isCurrentInvoice) {
            if (found) {
              this.form.invoiceId = found.id;
            } else {
              this.form.invoiceId = this.invoiceCodes[0].id;
            }
          }
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            item.professional.specialties.forEach((specialty) => {
              this.professionals.push({
                id: item.id,
                name: item.name,
                specialty,
              });
            });
          });
        })
        .catch(() => {});
    },
    loadPlanSettings() {
      if (!this.form.patient.insurance.plan.id) {
        return null;
      }

      const insuranceId = this.form.patient.insurance.id;
      const planId = this.form.patient.insurance.plan.id;
      return this.$http.get(`/insurances/${insuranceId}/plans/${planId}`)
        .then(({ data }) => {
          if (data.settings.general.billing) {
            if (data.settings.general.billing.blockValues) {
              this.planSettings.general.billing.blockValues = data.settings
                .general.billing.blockValues;
            }
            if (data.settings.general.billing.hideInsuranceValue) {
              this.planSettings.general.billing.hideInsuranceValue = data.settings
                .general.billing.hideInsuranceValue;
            }
          }
        })
        .catch(() => {});
    },
    blankForm() {
      return {
        id: '',
        key: '',
        status: '',
        startDate: '',
        endDate: '',
        appointmentId: '',
        professionalId: '',
        professionalName: '',
        specialtyCode: '',
        invoiceId: '',
        paymentDate: '',
        invoice: {
          id: '',
          code: '',
        },
        serviceType: '',
        consultationType: '',
        referral: {
          type: '',
          insurance: '',
          provider: '',
        },
        patient: {
          id: '',
          name: '',
          birthDate: '',
          gender: '',
          insurance: {
            id: '',
            name: '',
            plan: {
              id: '',
              name: '',
            },
            record: '',
            validity: '',
          },
        },
        expenses: [],
      };
    },
    blankPlanSettingsForm() {
      return {
        general: {
          billing: {
            blockValues: false,
            hideInsuranceValue: false,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
.forward-account {
  .eligibility-card {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin: $layout-spacing 0;
    padding: $layout-spacing;
    .eligibility-footer {
      margin-top: $layout-spacing-lg;
    }
  }
}
</style>
