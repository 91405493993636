import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Address from './entity/Address.vue';
import Autocomplete from './autocomplete/Autocomplete.vue';
import Bar from './bar/Bar.vue';
import BarChart from './chart/Bar.vue';
import ButtonReport from './common/ButtonReport.vue';
import Calendar from './calendar/Calendar.vue';
import DateNavBar from './date/NavBar.vue';
// import Editor from './editor/Classic.vue';
import EditorDocument from './editor/Document.vue';
import EntityFileManager from './entity/FileManager.vue';
import FormError from './form/FormError.vue';
import HorizontalBarChart from './chart/HorizontalBar.vue';
import InputDate from './inputs/Date.vue';
import InputNumber from './inputs/Number.vue';
import InputNumberStep from './inputs/NumberStep.vue';
import InputIdentity from './inputs/Identity.vue';
import Insurance from './entity/Insurance.vue';
import LineChart from './chart/Line.vue';
import Loading from './loading/Loading.vue';
import Pagination from './pagination/Pagination.vue';
import Person from './entity/Person.vue';
import PieChart from './chart/Pie.vue';
import SidePage from './side-page/SidePage.vue';
import Tab from './tabs/Tab.vue';
import Tabs from './tabs/Tabs.vue';

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('st-autocomplete', Autocomplete);
Vue.component('st-bar', Bar);
Vue.component('st-bar-chart', BarChart);
Vue.component('st-button-report', ButtonReport);
Vue.component('st-calendar', Calendar);
Vue.component('st-date-nav-bar', DateNavBar);
Vue.component('st-editor', EditorDocument);
Vue.component('st-editor-document', EditorDocument);
Vue.component('st-input-date', InputDate);
Vue.component('st-input-number', InputNumber);
Vue.component('st-input-number-step', InputNumberStep);
Vue.component('st-input-identity', InputIdentity);
Vue.component('st-entity-address', Address);
Vue.component('st-entity-file-manager', EntityFileManager);
Vue.component('st-entity-insurance', Insurance);
Vue.component('st-entity-person', Person);
Vue.component('st-form-error', FormError);
Vue.component('st-horizontal-bar-chart', HorizontalBarChart);
Vue.component('st-line-chart', LineChart);
Vue.component('st-loading', Loading);
Vue.component('st-pagination', Pagination);
Vue.component('st-pie-chart', PieChart);
Vue.component('st-side-page', SidePage);
Vue.component('st-tab', Tab);
Vue.component('st-tabs', Tabs);
