import Vue from 'vue';
import Http from './http';
import router from '../router/index';
import AvatarLetters from './avatar-letters';
import Bus from './bus';
import Cep from './cep';
import Dialog from './dialog';
import File from './file';
import Notification from './notification';
import TimeDiff from './time/time-diff';
import Toast from './toast';

Vue.use(Http, { router });
Vue.use(AvatarLetters);
Vue.use(Bus);
Vue.use(Cep);
Vue.use(Dialog);
Vue.use(File);
Vue.use(Notification);
Vue.use(TimeDiff);
Vue.use(Toast);
