import { routes as account } from './account';
import { routes as accommodationType } from './accommodation-type';
import { routes as appointment } from './appointment';
import { routes as audit } from './audit';
import { routes as surgeryAppointment } from './surgery-appointment';
import { routes as attendance } from './attendance';
import { routes as auth } from './auth';
import { routes as aso } from './aso';
import { routes as bankAccount } from './bank-account';
import { routes as billingAccount } from './billing/account';
import { routes as billingAttendance } from './billing/attendance';
import { routes as billingInvoice } from './billing/invoice';
import { routes as billingTransaction } from './billing/transaction';
import { routes as billingStatement } from './billing/statement';
import { routes as billingTransfer } from './billing/transfer';
import { routes as branch } from './branch';
import { routes as budget } from './budget';
import { routes as cashier } from './cashier';
import { routes as category } from './category';
import { routes as cbhpmTable } from './cbhpm-table';
import { routes as clinicalReport } from './clinical-report';
import { routes as client } from './client';
import { routes as collaborator } from './collaborator';
import { routes as costCenter } from './cost-center';
import { routes as dashboard } from './dashboard';
import { routes as documentTemplate } from './template';
import { routes as financial } from './financial';
import { routes as financialReport } from './financial-report';
import { routes as forward } from './forward';
import { routes as hospitalization } from './hospitalization';
import { routes as insurance } from './insurance';
import { routes as marker } from './marker';
import { routes as marketing } from './marketing';
import { routes as me } from './me';
import { routes as medicalRecord } from './medical-record';
import { routes as medicalReport } from './medical-report';
import { routes as message } from './message';
import { routes as messageTemplate } from './message-template';
import { routes as operationType } from './operation-type';
import { routes as other } from './other';
import { routes as partner } from './partner';
import { routes as patient } from './patient';
import { routes as paymentMethod } from './payment-method';
import { routes as phoneBook } from './phone-book';
import { routes as place } from './place';
import { routes as procedure } from './procedure';
import { routes as professional } from './professional';
import { routes as provider } from './provider';
import { routes as productFamily } from './product-family';
import { routes as productGroup } from './product-group';
import { routes as productPriceTable } from './product-price-table';
import { routes as surgery } from './surgery';
import { routes as schedule } from './schedule';
import { routes as setting } from './setting';
import { routes as stock } from './stock';
import { routes as stockBalance } from './stock-balance';
import { routes as stockEntry } from './stock-entry';
import { routes as stockInventory } from './stock-inventory';
import { routes as stockKit } from './stock-kit';
import { routes as stockOutput } from './stock-output';
import { routes as stockQuotation } from './stock-quotation';
import { routes as stockReport } from './stock-report';
import { routes as therapeuticPlan } from './therapeutic-plan';
import { routes as ticket } from './ticket';

export default [
  ...account,
  ...accommodationType,
  ...appointment,
  ...audit,
  ...surgeryAppointment,
  ...attendance,
  ...auth,
  ...aso,
  ...bankAccount,
  ...billingAccount,
  ...billingAttendance,
  ...billingInvoice,
  ...billingTransaction,
  ...billingStatement,
  ...billingTransfer,
  ...branch,
  ...budget,
  ...cashier,
  ...category,
  ...cbhpmTable,
  ...clinicalReport,
  ...client,
  ...collaborator,
  ...costCenter,
  ...dashboard,
  ...documentTemplate,
  ...financial,
  ...financialReport,
  ...forward,
  ...hospitalization,
  ...insurance,
  ...marker,
  ...marketing,
  ...me,
  ...medicalRecord,
  ...medicalReport,
  ...message,
  ...messageTemplate,
  ...operationType,
  ...other,
  ...partner,
  ...patient,
  ...paymentMethod,
  ...phoneBook,
  ...place,
  ...procedure,
  ...professional,
  ...provider,
  ...productFamily,
  ...productGroup,
  ...productPriceTable,
  ...surgery,
  ...setting,
  ...schedule,
  ...stock,
  ...stockBalance,
  ...stockEntry,
  ...stockInventory,
  ...stockKit,
  ...stockOutput,
  ...stockQuotation,
  ...stockReport,
  ...therapeuticPlan,
  ...ticket,
];
