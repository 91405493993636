import axios from 'axios';
import localforage from 'localforage';
import moment from 'moment';
import { http, httpX } from 'src/plugins/http';
import * as professionalApi from '@/app/professional/api';
import API from '@/helpers/api';
import { setInUse, unsetInUse } from '@/app/appointment/api';

const IN_USE = {
  intervalId: 0,
  ttl: 60,
};

export function upsert({ commit, state }, data) {
  commit('Appointment.UPSERT', data);

  if (state.show) {
    const found = state.items.find(({ key }) => key === data.key);
    if (found && found.key === state.form.key) {
      // commit('Appointment.SHOW_APPOINTMENT_MODAL', found);
    }
  }
}

export async function openAppointmentModal({ commit, state }, data) {
  commit('Appointment.SHOW_APPOINTMENT_MODAL', data);

  if (state.form.key && !state.form.id) {
    const inUseFn = () => {
      setInUse(state.form.key, IN_USE.ttl)
        .then((res) => {
          commit('Appointment.SET_APPOINTMENT_IN_USE_BY', res);
        })
        .catch(() => {});
    };

    inUseFn();
    IN_USE.intervalId = setInterval(inUseFn, IN_USE.ttl * 1000);
  }
}

export async function closeAppointmentModal({ commit, state }) {
  clearInterval(IN_USE.intervalId);

  if (state.form.key) {
    await unsetInUse(state.form.key);
    IN_USE.intervalId = 0;
  }

  commit('Appointment.HIDE_APPOINTMENT_MODAL');
}

export async function loadFilters({ state }) {
  const params = {
    limit: 0,
    active: true,
  };

  API('/schedule-expenses', params, httpX).fetch()
    .catch(() => ({ items: [] }))
    .then((data) => {
      state.filter.expenses = data.items;
    });

  API('/schedule-insurance-plans', params).fetch()
    .catch(() => ({ items: [] }))
    .then((data) => {
      state.filter.insurances = data.items;
    });

  API('/schedules', { ...params, type: 'elective' }).fetch()
    .catch(() => ({ items: [] }))
    .then((data) => {
      state.filter.schedules = data.items;
    });
}

function makeFilterParams(state) {
  const params = {
    type: 'elective',
    date: state.filter.date,
  };

  if (state.filter.expenseId) {
    const [expenseId] = state.filter.expenseId.split(':');
    params.expenseId = expenseId;
  }

  if (state.filter.insuranceId) {
    params.insuranceId = state.filter.insuranceId;
  }

  state.waitingListCount = 0;
  if (state.filter.scheduleId && state.filter.scheduleId !== 'ALL') {
    params.scheduleId = state.filter.scheduleId;
    // Check if have a patient on hold
    const found = state.filter.schedules
      .find(({ id }) => id === state.filter.scheduleId);
    state.waitingListCount = found ? found.waitingListCount : 0;
  }

  if (state.filter.professionalId) {
    params.professionalId = state.filter.professionalId;
  }

  return params;
}

let calendarAbortController;
export async function loadCalendar({ commit, dispatch, state }, availableDays = false) {
  if (calendarAbortController && state.loading) {
    calendarAbortController.abort();
  }

  commit('Appointment.SET_LOADING', true);

  const params = makeFilterParams(state);

  calendarAbortController = new AbortController();

  let hasData = false;

  if (params.scheduleId || state.filter.scheduleId === 'ALL') {
    try {
      const { data } = await http.get('/calendar', {
        signal: calendarAbortController.signal,
        params,
      });
      commit('Appointment.SET_SCHEDULES', data.schedules);
      commit('Appointment.SET_PROFESSIONALS', data.professionals);
      commit('Appointment.SET_INSURANCES', data.insurances);
      commit('Appointment.SET_ITEMS', data.items);
      if (availableDays) {
        dispatch('loadAvailableDays');
      }
      hasData = true;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
    }
  }

  if (!hasData) {
    commit('Appointment.SET_SCHEDULES', []);
    commit('Appointment.SET_PROFESSIONALS', []);
    commit('Appointment.SET_INSURANCES', []);
    commit('Appointment.SET_ITEMS', []);
    state.available.days = [];
  }

  commit('Appointment.SET_LOADING', false);

  await localforage.setItem('appointmentFilters', params);
}

export async function loadAvailableDays({ state }) {
  const params = makeFilterParams(state);

  let canLoad = ['expenseId', 'insuranceId', 'scheduleId', 'professionalId']
    .some(v => (v in params && params[v]));

  if (canLoad) {
    const date = moment(state.currentMonth || state.filter.date);
    const today = moment();

    if (date.isBefore(today, 'month')) {
      canLoad = false;
    } else {
      params.startDate = date.isSame(today, 'month')
        ? today.startOf('day')
        : date.startOf('month');
      params.endDate = date.clone().endOf('month');
    }
  }

  if (canLoad) {
    params.startDate = params.startDate.format('YYYY-MM-DD');
    params.endDate = params.endDate.format('YYYY-MM-DD');

    http.get('/calendar/available-days', { params })
      .then(({ data }) => {
        state.available.days = data;
      })
      .catch(() => {
        state.available.days = [];
      });
  } else {
    state.available.days = [];
  }
}

export async function apiFetchProfessionals({ state }) {
  if (state.api.professional.status !== 'ready') {
    state.api.professional.status = 'loading';
    const data = await professionalApi.allActive();

    state.api.professional.items = data.items;
    state.api.professional.status = 'ready';
  }

  return state.api.professional.items;
}
